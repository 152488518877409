import { createElement } from 'react';

let elementId = 0

function h(el, children, props) {
  const elProps = {
    key: `marked-react-render-${elementId}`,
  };

  elementId += 1;
  return createElement(el, { ...props, ...elProps }, children);
}

const renderer = {
  heading(children, level) {
    return h(`h${level}`, children, { className: 'font-bold leading-tight uppercase text-xl mt-4' });
  },
  paragraph(children) {
    return h('p', children, { className: 'text-base mb-2' });
  }
}

export default renderer
