import React, { Component } from 'react'
import * as styles from './Button.scss'

export default function Button({ link, children }) {
  const props = {
    href: link || '#'
  }
  return (
    <a {...props} className="button">
      {children}
    </a>
  )
}
