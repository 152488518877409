import { useState, useEffect } from 'react'
import axios from 'axios'
import './App.scss'
import Button from './core/Button'
import Hero from './core/Hero'
import Markdown from 'marked-react'
import renderer from './renderer'

import home from 'bundle-text:./pages/home.md'

export function App() {
  const [release, setRelease] = useState({ version: '', url: '' })
  const gettingStarted = 'https://github.com/outlander-app/outlander-osx/blob/master/commands.md'

  useEffect(async ()=> {
    try {
      let result = await axios.get('/api/v2/updates?prerelease=false')
      if (result.status === 200 || result.status === 304) {
        setRelease(result.data)
      } else if (result.status === 201) {
        setRelease({
          version: '',
          url: 'https://github.com/outlander-app/outlander/releases/'
        })
      }
    } catch {
    }
  }, [])

  return (
    <div>
      <Hero title="Outlander" subTitle="A Mac game client for DragonRealms">
        <div className="buttonUnit">
          <Button link={gettingStarted}>Get Started</Button>
          <Button link={release.url}>Download Outlander {release.version}</Button>
        </div>
      </Hero>
      <div className="container">
        <Markdown value={home} renderer={renderer}/>
      </div>
    </div>
  )
}
