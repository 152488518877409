import './Hero.scss'

export default function Hero(props) {
  const subTitle = props.subTitle
    ? (<p>{props.subTitle}</p>)
    : null
  return (
    <div className="hero">
      <h1>{props.title}</h1>
      {subTitle}
      {props.children}
    </div>
  )
}
